/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import t from "./Accessor.js";
import { handlesGroup as r, makeHandle as s } from "./handleUtils.js";
import { subclass as n } from "./accessorSupport/decorators/subclass.js";
class i {
  constructor() {
    this._emitter = new i.EventEmitter(this);
  }
  emit(e, t) {
    return this._emitter.emit(e, t);
  }
  on(e, t) {
    return this._emitter.on(e, t);
  }
  once(e, t) {
    return this._emitter.once(e, t);
  }
  hasEventListener(e) {
    return this._emitter.hasEventListener(e);
  }
}
!function (o) {
  class c {
    constructor(e = null) {
      this._target = e, this._listenersMap = null;
    }
    clear() {
      this._listenersMap?.clear(), this._listenersMap = null;
    }
    destroy() {
      this.clear();
    }
    emit(e, t) {
      let r = this._listenersMap?.get(e);
      if (!r) return !1;
      const s = this._target || this;
      let n = !1;
      for (const i of r.slice()) {
        const e = "deref" in i ? i.deref() : i;
        e ? e?.call(s, t) : n = !0;
      }
      return n && (r = r.filter(e => !("deref" in e) || null != e.deref()), this._listenersMap.set(e, r)), r.length > 0;
    }
    on(e, t) {
      if (Array.isArray(e)) {
        const s = e.map(e => this.on(e, t));
        return r(s);
      }
      if (e.includes(",")) throw new TypeError("Evented.on() with a comma delimited string of event types is not supported");
      this._listenersMap ??= new Map();
      const n = this._listenersMap.get(e) || [];
      return n.push(t), this._listenersMap.set(e, n), s(() => {
        const r = this._listenersMap?.get(e),
          s = r?.indexOf(t) ?? -1;
        s >= 0 && r.splice(s, 1);
      });
    }
    once(e, t) {
      const r = this.on(e, e => {
        r.remove();
        const s = "deref" in t ? t.deref() : t;
        s?.call(null, e);
      });
      return r;
    }
    hasEventListener(e) {
      const t = this._listenersMap?.get(e);
      return null != t && t.length > 0;
    }
  }
  o.EventEmitter = c, o.EventedMixin = t => {
    let r = class extends t {
      constructor() {
        super(...arguments), this._emitter = new c();
      }
      destroy() {
        this._emitter.clear();
      }
      emit(e, t) {
        return this._emitter.emit(e, t);
      }
      on(e, t) {
        return this._emitter.on(e, t);
      }
      once(e, t) {
        return this._emitter.once(e, t);
      }
      hasEventListener(e) {
        return this._emitter.hasEventListener(e);
      }
    };
    return r = e([n("esri.core.Evented")], r), r;
  };
  let l = class extends t {
    constructor() {
      super(...arguments), this._emitter = new i.EventEmitter(this);
    }
    destroy() {
      this._emitter.clear();
    }
    emit(e, t) {
      return this._emitter.emit(e, t);
    }
    on(e, t) {
      return this._emitter.on(e, t);
    }
    once(e, t) {
      return this._emitter.once(e, t);
    }
    hasEventListener(e) {
      return this._emitter.hasEventListener(e);
    }
  };
  l = e([n("esri.core.Evented.EventedAccessor")], l), o.EventedAccessor = l;
}(i || (i = {}));
const o = i;
export { o as default };